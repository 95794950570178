import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.search-text-filter input').on('change', function() {
      var searchContents = $(this).val();
      if(searchContents.length === 0) {
        $('.search-text-clear-filter').hide();
      } else {
        $('.search-text-clear-filter').show();
      }
    });
  }

  clearSearchResults(event) {
    $('.search-text-clear-filter').hide();
    $('.search-text-filter input').val('');
  }
}
