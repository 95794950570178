import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    filterBy: String,
    filterScope: String,
    eventId: String,
    eventTitle: String
  };

  connect() {
    this.filterBy = 'all';
    this.filterScope = '.candidate-note';
    this.applyFilterToEntries();
  }

  filterEntries(event) {
    var data = $(event.target).data();
    this.filterBy = data.filterBy;
    this.eventId = data.eventId;
    this.eventTitle = data.eventTitle;
    this.applyFilterToEntries();

    event.preventDefault();
  }

  applyFilterToEntries() {
    $('.candidate-note').hide();
    $('.candidate-notes__show-more').hide();
    $('.candidate-notes__show-less').hide();

    switch(this.filterBy) {
      case 'human':
        $('.candidate-notes .selected-label').html('Human');
        this.filterScope = ".candidate-note[data-filter-type='human']";
        break;
      case 'system':
        $('.candidate-notes .selected-label').html('System');
        this.filterScope = ".candidate-note[data-filter-type='system']";
        break;
      case 'event':
        $('.candidate-notes .selected-label').html(this.eventTitle);
        this.filterScope = ".candidate-note[data-event-id='" + this.eventId + "']";
        break;
      default:
        $('.candidate-notes .selected-label').html('All entries');
        this.filterScope = ".candidate-note";
    }

    $(this.filterScope + ":lt(12)").show();

    if($(this.filterScope).length >= 12) {
      $('.candidate-notes__show-more').show();
    } else {
      $('.candidate-notes__show-more').hide();
    }
  }

  showMore() {
    $(this.filterScope).show();
    $('.candidate-notes__show-more').hide();
    $('.candidate-notes__show-less').show();
  }

  showLess() {
    $(this.filterScope + ':gt(12)').hide();
    $('.candidate-notes__show-less').hide();
    $('.candidate-notes__show-more').show();
  }
}
