import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggleCheckBoxes(event){
  	$('.candidate-row:visible input[type=checkbox]').prop('checked', $(event.target).is(":checked"));
    this.toggleCheckBox(event);
  }

  toggleCheckBox(event) {
    var count = $('.candidate-row:visible input[type=checkbox]:checked').length;

    if(count == 0) {
      $('.candidate-bulk-change-status .candidate-bulk-change-status__info').html('');
    } else if (count == 1) {
      $('.candidate-bulk-change-status .candidate-bulk-change-status__info').html('1 candidate selected');
    } else {
      $('.candidate-bulk-change-status .candidate-bulk-change-status__info').html(count + ' candidates selected');
    }
  }

  submitBulkApplyForm(event) {
    var count = $('.candidate-row:visible input[type=checkbox]:checked').length;

    if(count > 0) {
      $('.candidate-bulk-change-status .candidate-bulk-change-status__info').html("<i class='fa fa-refresh fa-spin'></i> Saving changes");
      $(this.element).closest('form')[0].requestSubmit(); // trigger a form submission with a Turbo response
    } else {
      $('.candidate-bulk-change-status .candidate-bulk-change-status__info').html('Please select at least one candidate.');
    }
  }

}
