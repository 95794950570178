import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  disableSubmitButton(event) {
    var remoteForm = $(this.element);
  	var eventTarget = $(event.target);
  	var disableWithText = eventTarget.data('disable-with');

    remoteForm[0].requestSubmit();
  	eventTarget.prop('disabled', 'disabled');

  	if(disableWithText !== undefined){
      eventTarget.val(disableWithText);
  	}
  }
}
