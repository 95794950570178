import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
export default class extends Controller {

  edit(event) {
    $('.candidate-header-show').hide();
    $('.candidate-header-edit').show();
    event.preventDefault();
  }

  cancelEdit(event) {
    $('.candidate-header-edit').hide();
    $('.candidate-header-show').show();

    event.preventDefault();
  }
  static_targets = ["selectFee"]

  change(event) {
    console.log(event.target.value);
    console.log(event.target.options[event.target.value]);
    console.log(event.target.options);

    get(`/candidates/event_fee_types?candidate_type_id=${event.target.value}&target=${this.static_targets[0]}`, {
      responseKind: "turbo-stream"
    })
    console.log('wtf2');
  }

}
