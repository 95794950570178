import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleContentShelf(event) {
    var container = $(event.target).closest('.twist-down');

    if(container.hasClass('active')) {
      container.removeClass('active');
    } else {
      container.addClass('active');
    }

    event.preventDefault();
  }
}
