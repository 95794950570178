import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleViewMoreContent(event) {
  	var viewMoreBtn = $(event.target);
    var showClass = viewMoreBtn.data('showClass')

    viewMoreBtn.hide();
    $(showClass).show();

    event.preventDefault();
  }
}
