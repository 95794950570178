import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.formElem = this.element;

    $(this.element)[0].requestSubmit();

    this.refreshAllOptions();
  }

  refreshAllOptions() {
    $(this.element).find('label').removeClass('active');
    var filterContent = '';

    $(this.element).find('label input:checked').closest('label').each(function( index ) {
      var label = $(this).data('label');
      var toggleClass = $(this).data('toggleClass');

      $(this).addClass('active');
      filterContent += '<span class="stub"><span class="stub-label">' + label + '</span><a class="stub-remove" data-toggle-class="' + toggleClass + '" href="#"><i class="fa fa-times-circle"></i></a></span>';
    });

    $('.filter-control').append(filterContent);
  }

  toggleOption(){
    $('.filter-control .stub').remove();
    this.refreshAllOptions();
    $(this.element)[0].requestSubmit(); // trigger a form submission with a Turbo response
    $('.loader').show();
    $('.candidate-row').hide();
  }

 }
