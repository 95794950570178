import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  submitForm(event){
    var flagForm = $(this.element).find('form');

    flagForm[0].requestSubmit();
    flagForm.find('input').prop('disabled', 'disabled');
    flagForm.find('select').prop('disabled', 'disabled');

    $(event.target).closest('.input-field-container').find('.fa-loading').show();
  }

}
